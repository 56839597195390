var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.columns),function(column){return _c('div',{key:column.id,staticClass:"tw-my-10"},[_c('DataTable',_vm._b({key:_vm.identifier,attrs:{"loading":_vm.loading[column.id],"headers":_vm.HEADERS,"can-search":true,"min-search-chars":3,"can-select":true,"all-record-ids":_vm.leadIds[column.id]},on:{"changePage":function($event){return _vm.fetchLeads(column, $event)},"filter":function($event){return _vm.loadDataForColumn(column, $event)}},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"tw-my-2 tw-flex tw-flex-wrap tw-items-center tw-justify-between"},[_c('h3',[_vm._v(_vm._s(column.title))]),(_vm.breakingLeadsForColumnId === column.id)?_c('div',[_c('i',{staticClass:"fa fa-spinner fa-spin tw-mr-2"}),_c('span',{staticClass:"font-bold"},[_vm._v("Leads worden gebreakt ... ")])]):_c('button',{staticClass:"action tw-bg-danger",attrs:{"type":"button","title":"Break geselecteerde leads","disabled":!(_vm.selectedLeads[column.id] && _vm.selectedLeads[column.id].length)},on:{"click":function($event){return _vm.showBreakModal(column)}}},[_c('i',{staticClass:"fa fa-trash"}),_vm._v(" Break geselecteerde leads ")])])]},proxy:true},{key:"item-id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'LeadDetails',  params: { id: item.id }}},nativeOn:{"click":function($event){return _vm.saveLeadIds(column.id)}}},[_vm._v(" "+_vm._s(item.entity_data.address)+" ")])]}},{key:"item-language",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tw-uppercase"},[_vm._v(" "+_vm._s(item.language)+" ")])]}},{key:"item-candidate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-gap-x-2"},[(item.candidate)?_c('span',[_vm._v(_vm._s(item.candidate.name))]):_vm._e(),(item.candidate)?_c('ContactNotes',{attrs:{"object":item.candidate}}):_vm._e()],1)]}},{key:"item-contact_score",fn:function(ref){
var item = ref.item;
return [(item.candidate && item.candidate.contact_score)?_c('ContactScore',{attrs:{"score":item.candidate.contact_score,"contact-id":item.candidate.id}}):_c('span',[_vm._v("-")])]}},{key:"item-last_activity",fn:function(ref){
var item = ref.item;
return [(item.last_activity_name)?_c('div',[_vm._v(" "+_vm._s(item.last_activity_name)+": "+_vm._s(_vm._f("date-day")(item.last_activity_date))+" ")]):_vm._e()]}},{key:"item-next_activity",fn:function(ref){
var item = ref.item;
return [(item.next_activity_name)?_c('span',[_vm._v(" "+_vm._s(item.next_activity_name)+": "+_vm._s(_vm._f("date-day")(item.next_activity_date))+" ")]):_vm._e()]}},{key:"item-actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-flex"},[(item.next_activity_name)?[_c('button',{staticClass:"action tw-bg-success hover:tw-bg-darken-success",attrs:{"title":"Bewerken"},on:{"click":function($event){return _vm.editActivity(item.next_activity_id)}}},[_vm._v(" Bewerken ")]),_c('button',{staticClass:"action tw-bg-warning",attrs:{"title":"Afpunten"},on:{"click":function($event){return _vm.selectLead('finish', item, item.next_activity_id)}}},[_vm._v(" Afpunten ")])]:_c('button',{staticClass:"action tw-bg-primary",attrs:{"title":"Nieuw"},on:{"click":function($event){return _vm.selectLead('new', item)}}},[_vm._v(" Nieuw ")])],2)]}}],null,true),model:{value:(_vm.selectedLeads[column.id]),callback:function ($$v) {_vm.$set(_vm.selectedLeads, column.id, $$v)},expression:"selectedLeads[column.id]"}},'DataTable',_vm.leads[column.id],false))],1)}),_c('ActivityFeedbackModal',{ref:"feedbackModal",attrs:{"activity":_vm.selectedActivity,"lead":_vm.selectedLead,"showBreakButton":true,"show-checkbox":_vm.feedbackModalCheckbox,"title":_vm.feedbackModalTitle},on:{"gotoLead":_vm.gotoLead,"activity-finished":_vm.activityFinished}}),_c('ActivityFeedbackModal',{ref:"breakModal",attrs:{"title":"Geselecteerde leads breaken","lead":null,"multi-mode":true,"show-break-button":true,"show-details-button":false,"show-finish-button":false,"handle-success-modal":false},on:{"finished":_vm.breakSelectedLeads}}),_c('NewActivityModal',{ref:"newActivityModal",attrs:{"lead":_vm.selectedLead},on:{"activity-saved":_vm.init}}),_c('EditActivityModal',{ref:"editActivityModal",on:{"activity-changed":_vm.init}}),_c('ActivityCancelModal',{ref:"activityCancelModal",attrs:{"activity":_vm.selectedActivity},on:{"activity-cancelled":_vm.init}}),_c('LeadBreakHmodhFormOpener',{ref:"hmodhOpener"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }